<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="teachers"
          title="Guru"
          subtitle="Halaman untuk menambahkan data guru"
          add-btn="Tambah Data"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          @delete-item="confirmDestroy"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Guru': 'Edit Guru'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="teacher.employee_uuid"
                label="Nama Guru"
                dense
                item-value="uuid"
                item-text="name"
                :items="employees"
                outlined
                clearable
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="teacher.status"
                :items="status"
                label="Status Guru"
                item-text="name"
                item-value="uuid"
                dense
                clearable
                outlined
              >
              </v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-model="teacher.description"
                dense
                outlined
                label="Keterangan"
              >
              </v-textarea>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <div class="d-flex align-center">
                Status : <v-switch
                  v-model="teacher.is_active"
                  class="ml-4"
                ></v-switch>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Guru"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
      <template v-slot:body>
        <p>
          <b>Data yang dihapus termasuk :</b>
        </p>
        <p>
          - Data Guru
          <br>
          - Data Login Pegawai
          <br>
          - Data Jam Pelajaran
          <br>
          - Data Mata Pelajaran
          <br>
          - Data Bab Pelajaran
          <br>
          - Data Soal Latihan/Ujian/Materi
        </p>
        <p>Seluruh data dari Guru ini akan dihapus <b>PERMANENT</b>. Silahkan ketik <b>"Delete Teacher"</b> untuk validasi penghapusan Guru.</p>

        <v-text-field
          v-model="validateDelete"
          label="'Delete Teacher'"
          dense
          type="text"
          outlined
        ></v-text-field>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'Teacher',
  components: {
    DataTablePagination,
    ModalDialog,
    MainCard,
    SkeletonLoaderTable,
    ConfirmDialog,
  },
  setup(props, context) {
    const attrs = ref({
      class: 'mb-6',
      boilerplate: false,
      elevation: 5,
      types: {},
    })
    const validateDelete = ref(null)
    const page = ref(1)
    const search = ref('')
    const dialog = ref(null)
    const width = ref(600)
    const totalPages = ref(0)
    const totalItems = ref(0)
    const modalDialog = ref(false)
    const confirmDialog = ref(false)
    const isLoadingTable = ref(true)
    const isLoadingData = ref(false)
    const isLoadingButton = ref(false)
    const status = ref(['Honorer', 'PNS'])
    const employees = ref([])
    const teachers = ref([])
    const teacher = ref({
      employee_uuid: '',
      is_active: '',
      status: '',
      description: '-',
    })
    const formValid = ref(false)
    const school = ref('')
    const schoolUuid = ref('')
    const userUuid = ref('')

    const headers = ref([
      { text: 'No', value: 'index' },
      { text: 'NIP', value: 'employee.nip' },
      { text: 'NIK', value: 'employee.nik' },
      { text: 'Nama Guru', value: 'employee.name' },
      { text: 'Status', value: 'status' },
      { text: 'Aktif', value: 'is_active' },
      { text: 'Aksi', value: 'actions' },
    ])
    const filterQuery = ref({
      search: '',
    })
    const getSchoolUuid = () => {
      school.value = JSON.parse(localStorage.getItem('user')).user.details
      school.value.forEach(el => {
        if (el.school === undefined) {
          schoolUuid.value = el.uuid

          return
        }

        if (el.school === null) {
          return
        }
        userUuid.value = JSON.parse(localStorage.getItem('user')).user.uuid
        schoolUuid.value = el.school.uuid
      })

      return true
    }

    const listEmployee = async (params = {}) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('employee', {
        ...params,
        page: page.value,
        school: schoolUuid.value,
      }).then(
        ({ data }) => {
          employees.value = data.data
        },
        err => {
          console.error(err)
        },
      )
      isLoadingData.value = false
    }

    const listTeacher = async (params = {}) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('teacher', {
        ...params,
        page: page.value,
        school_uuid: schoolUuid.value,
      }).then(
        ({ data }) => {
          teachers.value = data.data.map((teachers, index) => ({
            ...teachers,
            index: index + 1,
          }))
          totalItems.value = data.meta.total
          totalPages.value = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      isLoadingTable.value = false
      isLoadingData.value = false
    }

    const showSnackbar = snackbarData => {
      context.root.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }

    const resetForm = () => {
      teacher.value.is_active = ''
      teacher.value.status = ''
      teacher.value.description = ''
    }

    const showFormAdd = () => {
      dialog.value = 'add'
      resetForm()
      modalDialog.value = true
    }

    const add = async () => {
      teacher.value.is_active = teacher.value.is_active ? '1' : '0'
      isLoadingButton.value = true
      teacher.value.description = teacher.value.description === '' ? '-' : teacher.value.description
      await context.root.$services.ApiServices.add('teacher', teacher.value).then(
        res => {
          showSnackbar({
            text: 'Add Teacher Has Successfully',
            color: 'success',
          })
        },
        err => {
          console.error(err)
        },
      )
      listTeacher(filterQuery.value)
      isLoadingButton.value = false
      modalDialog.value = false
      resetForm()
    }

    const showFormEdit = async uuid => {
      dialog.value = 'edit'
      modalDialog.value = true
      context.root.$services.ApiServices.get('teacher', uuid).then(
        ({ data }) => {
          teacher.value.uuid = data.data.uuid
          teacher.value.employee_uuid = data.data.employee.uuid
          teacher.value.status = data.data.status
          teacher.value.description = data.data.description
        },
        err => console.error(err),
      )
    }

    const update = async () => {
      isLoadingButton.value = true
      teacher.value.is_active = teacher.value.is_active ? '1' : '0'
      await context.root.$services.ApiServices.update('teacher', teacher.value, teacher.value.uuid).then(
        res => {
          listTeacher(filterQuery.value)
          resetForm()
          modalDialog.value = false
          showSnackbar({
            text: 'Update Teacher Has Successfully',
            color: 'success',
          })
        },
        err => console.error(err),
      )
      isLoadingButton.value = false
    }
    const handlerButton = () => {
      if (dialog.value === 'add') add()
      else update()
    }
    const confirmDestroy = uuid => {
      validateDelete.value = null
      confirmDialog.value = true
      teacher.value.uuid = uuid
    }

    const destroy = async () => {
      if (validateDelete.value === null || validateDelete.value !== 'Delete Teacher') {
        showSnackbar({
          text: "Please, type 'Delete Teacher' first !!! ",
          color: 'error',
        })

        return
      }
      isLoadingButton.value = true
      await context.root.$services.ApiServices.destroy('teacher', teacher.value.uuid).then(
        res => {
          listTeacher(filterQuery.value)
          showSnackbar({
            text: 'Delete Subject Has Successfully',
            color: 'error',
          })
        },
        err => console.error(err),
      )
      isLoadingButton.value = false
      confirmDialog.value = false
    }

    const paginationHandler = pageNumber => {
      page.value = pageNumber
    }

    const searchHandler = teacher => {
      filterQuery.value.search = teacher
      listTeacher(filterQuery.value)
    }

    watch(page, listTeacher, searchHandler, paginationHandler)
    watch(
      teacher.value,
      () => {
        const valid = []
        const requiredField = ['employee_uuid', 'status', 'description']
        Object.entries(teacher.value).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        formValid.value = !valid.includes(false)
      },
      { deep: true },
    )
    onMounted(() => {
      getSchoolUuid()
      listTeacher()
      listEmployee()
    })

    return {
      validateDelete,
      getSchoolUuid,
      school,
      schoolUuid,
      userUuid,
      attrs,
      add,
      update,
      destroy,
      employees,
      teachers,
      teacher,
      headers,
      search,
      status,
      listEmployee,
      listTeacher,
      showFormAdd,
      showFormEdit,
      showSnackbar,
      resetForm,
      page,
      dialog,
      width,
      totalPages,
      totalItems,
      modalDialog,
      confirmDialog,
      confirmDestroy,
      isLoadingData,
      isLoadingTable,
      isLoadingButton,
      paginationHandler,
      searchHandler,
      handlerButton,
      formValid,
    }
  },
}
</script>

<style>
</style>
